<template>
    <div class="container">
        <b-row class="py-2">
            <b-col>
                <b-card>
                    <b-card-title>Equipos disponibles</b-card-title>
                    <b-card-sub-title
                        >Solicita al paciente la medición de sus dispositivos.
                    </b-card-sub-title>
                    <b-row class="mt-1">
                        <b-col>
                            <div
                                v-for="(dispositivo, index) in dispositivos"
                                :key="index"
                                class="py-1"
                            >
                                <b-row
                                    :class="`align-items-center  ${
                                        index !== dispositivos.length - 1
                                            ? 'border-bottom py-1'
                                            : ''
                                    }`"
                                    style="min-height: 300px"
                                >
                                    <!-- col imagen -->
                                    <b-col cols="auto">
                                        <img
                                            :src="dispositivo.foto"
                                            alt="Foto del dispositivo"
                                            height="160"
                                            width="160"
                                            class="rounded"
                                        />
                                    </b-col>
                                    <!-- col nombre y último uso -->
                                    <b-col cols="2" class="mr-4">
                                        <p
                                            class="font-weight-bold font-medium-5 py-1"
                                        >
                                            {{ dispositivo.nombre }}
                                        </p>
                                        <p
                                            class="font-weight-bold font-medium-1 py-0 my-0"
                                        >
                                            Último uso:
                                        </p>
                                        <p class="font-small-4 my-0">
                                            {{ dispositivo.ultimo_uso }}
                                        </p>
                                    </b-col>
                                    <!-- col cuerpo -->
                                    <b-col
                                        class="pl-0 w-50 text-center align-items-start"
                                    >
                                        <!-- row comparaciones -->
                                        <b-row class="justify-content-start">
                                            <b-checkbox
                                                switch
                                                class="px-1 py-25"
                                                v-model="
                                                    dispositivo.medicion_ultima_asistencia
                                                "
                                                >Comparar con la última
                                                asistencia</b-checkbox
                                            >
                                            <b-checkbox
                                                switch
                                                class="px-1 py-25"
                                                v-model="
                                                    dispositivo.medicion_anterior
                                                "
                                                >Comparar con la última
                                                medición</b-checkbox
                                            >
                                        </b-row>
                                        <!-- row lista de variable leidas -->
                                        <b-row class="justify-content-center">
                                            <b-col>
                                                <b-spinner
                                                    v-show="
                                                        dispositivo.estado == 1
                                                    "
                                                    class="my-1"
                                                    variant="primary"
                                                ></b-spinner>

                                                <!-- medicion de la asistencia actual  -->
                                                <p
                                                    v-if="
                                                        dispositivo.estado == 2
                                                    "
                                                    class="font-medium-3 font-weight-bold"
                                                >
                                                    Medición actual
                                                </p>
                                                <div
                                                    v-if="
                                                        dispositivo.estado == 2
                                                    "
                                                    class="overflow-auto p-50 d-flex flex-nowrap animate__animated animate__fadeIn"
                                                >
                                                    <div
                                                        v-for="(
                                                            valor, index
                                                        ) in dispositivo.valores"
                                                        :key="index"
                                                        class="indicador-medicion bg-gradient-danger flex-shrink-0 px-1 py-50 mx-2"
                                                    >
                                                        <p
                                                            class="text-left p-25 border-bottom font-weight-bold text-capitalize"
                                                        >
                                                            {{ index }}
                                                        </p>
                                                        <p class="device-font">
                                                            {{ valor }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <!-- medición de la ultima asistencia -->
                                                <p
                                                    v-if="
                                                        dispositivo.medicion_ultima_asistencia
                                                    "
                                                    class="mt-1 font-medium-3 font-weight-bold"
                                                >
                                                    Última asistencia
                                                </p>

                                                <div
                                                    v-if="
                                                        dispositivo.medicion_ultima_asistencia
                                                    "
                                                    class="overflow-auto p-50 d-flex flex-nowrap animate__animated animate__fadeIn"
                                                >
                                                    <div
                                                        v-for="(
                                                            valor, index
                                                        ) in dispositivo.valores"
                                                        :key="index"
                                                        class="indicador-medicion-pasada bg-gradient-success flex-shrink-0 px-1 py-50 mx-2"
                                                    >
                                                        <p
                                                            class="text-left p-25 mb-0 border-bottom font-weight-bold text-capitalize"
                                                        >
                                                            {{ index }}
                                                        </p>
                                                        <p
                                                            class="device-font p-0 m-0"
                                                        >
                                                            {{ valor }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <!-- medición anterior -->
                                                <p
                                                    v-if="
                                                        dispositivo.medicion_anterior
                                                    "
                                                    class="mt-1 font-medium-3 font-weight-bold"
                                                >
                                                    Última medición
                                                </p>
                                                <div
                                                    v-if="
                                                        dispositivo.medicion_anterior
                                                    "
                                                    class="overflow-auto p-50 d-flex flex-nowrap animate__animated animate__fadeIn"
                                                >
                                                    <div
                                                        v-for="(
                                                            valor, index
                                                        ) in dispositivo.valores"
                                                        :key="index"
                                                        class="indicador-medicion-pasada bg-gradient-warning flex-shrink-0 px-1 py-50 mx-2"
                                                    >
                                                        <p
                                                            class="text-left p-25 mb-0 border-bottom font-weight-bold text-capitalize"
                                                        >
                                                            {{ index }}
                                                        </p>
                                                        <p class="device-font">
                                                            {{ valor }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <!-- col botones solicitar y solicitar de nuevo  -->
                                    <b-col
                                        cols="auto"
                                        class="text-center animate__animated animate__fadeInRight"
                                    >
                                        <b-button
                                            v-if="dispositivo.estado == 0"
                                            :disabled="dispositivo.estado !== 0"
                                            variant="outline-primary"
                                            pill
                                            @click="solicitarMedicion(index)"
                                            class="p-75"
                                        >
                                            <feather-icon
                                                icon="PowerIcon"
                                                size="30"
                                            >
                                            </feather-icon>
                                        </b-button>

                                        <b-button
                                            v-if="dispositivo.estado == 2"
                                            variant="outline-success"
                                            pill
                                            class="p-75 animate__animated animate__rotateIn"
                                            v-b-tooltip.hover.top="
                                                'Solicitar de nuevo'
                                            "
                                            @click="solicitarMedicion(index)"
                                        >
                                            <feather-icon
                                                icon="RefreshCcwIcon"
                                                size="30"
                                            ></feather-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-overlay
                    id="observacion-overlay"
                    :show="mostrarModalObservacion"
                    blur="1rem"
                    variant="transparent"
                    rounded
                >
                    <b-card>
                        <b-card-title>Observación</b-card-title>
                        <b-card-sub-title
                            >Realiza una observación general de la asistencia
                            realizada.</b-card-sub-title
                        >
                        <b-row class="d-flex justify-content-end">
                            <b-button
                                :disabled="observacionEstado"
                                variant="flat-success"
                                pill
                                @click="mostrarModalObservacion = true"
                            >
                                <feather-icon icon="SaveIcon"></feather-icon>
                            </b-button>
                        </b-row>
                        <b-form-textarea
                            :disabled="observacionEstado"
                            class="mt-1"
                        ></b-form-textarea>
                    </b-card>

                    <template v-slot:overlay>
                        <div class="text-center p-3">
                            <p>
                                <strong id="form-confirm-label"
                                    >¿Deseas guardar la observación?</strong
                                >
                            </p>
                            <div class="d-flex justify-content-center">
                                <b-button
                                    variant="outline-danger"
                                    class="mr-3"
                                    @click="mostrarModalObservacion = false"
                                >
                                    No
                                </b-button>
                                <b-button
                                    variant="outline-success"
                                    @click="guardarObservacion"
                                >
                                    Sí
                                </b-button>
                            </div>
                        </div>
                    </template>
                </b-overlay>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-title>Recomendaciones</b-card-title>
                    <b-card-sub-title
                        >Las recomendaciones que realices el paciente las verá
                        en su lista de actividades.</b-card-sub-title
                    >

                    <b-row class="my-1 justify-content-end">
                        <b-col cols="auto">
                            <b-button variant="flat-info" pill class="p-25">
                                <feather-icon
                                    icon="PlusIcon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-form-textarea></b-form-textarea>
                    <draggable
                        v-model="recomendaciones"
                        @change="onDraggableChange"
                    >
                        <div
                            v-for="(recomendacion, index) in recomendaciones"
                            :key="index"
                        >
                            <b-row class="align-items-center mt-1 cursor-move">
                                <b-col class="tres-puntos">
                                    <span v-if="recomendacion.estado == 0">{{
                                        recomendacion.nombre
                                    }}</span>
                                    <b-form-input
                                        v-if="recomendacion.estado == 1"
                                        :value="recomendacion.nombre"
                                        size="sm"
                                    ></b-form-input>
                                </b-col>
                                <b-col cols="auto">
                                    <b-button
                                        v-if="recomendacion.estado == 1"
                                        variant="flat-success"
                                        size="sm"
                                        pill
                                        @click="recomendacion.estado = 0"
                                    >
                                        <feather-icon
                                            icon="SaveIcon"
                                        ></feather-icon>
                                    </b-button>
                                    <b-button
                                        v-if="recomendacion.estado == 0"
                                        variant="flat-warning"
                                        size="sm"
                                        pill
                                        @click="recomendacion.estado = 1"
                                    >
                                        <feather-icon
                                            icon="EditIcon"
                                        ></feather-icon>
                                    </b-button>
                                    <b-button
                                        v-if="recomendacion.estado == 0"
                                        variant="flat-danger"
                                        size="sm"
                                        pill
                                    >
                                        <feather-icon
                                            icon="DeleteIcon"
                                        ></feather-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </draggable>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import { ref, onMounted } from "@vue/composition-api";
export default {
    components: {
        draggable,
    },
    setup() {
        const dispositivos = ref([
            {
                foto: "/img/dispositivos/blood_pressure.jpg",
                nombre: "Blood pressure",
                medicion_anterior: false,
                medicion_ultima_asistencia: false,
                estado: 0,
                ultimo_uso: "22 ago. 2022 03:30 pm",
                valores: {
                    sistolica: 12,
                    diastolica: 234,
                    pulso1: 23,
                    pulso2: 34,
                    pulso3: 456,
                    pulso4: 540,
                    puls5: 44,
                },
            },
            {
                foto: "/img/dispositivos/body_composition.jpg",
                nombre: "Body composition",
                medicion_anterior: false,
                medicion_ultima_asistencia: false,
                estado: 0,
                ultimo_uso: "22 ago. 2022 03:30 pm",
                valores: {
                    peso: 0,
                    grasa: 0,
                    altura: 0,
                },
            },
            {
                foto: "/img/dispositivos/weight_scale.jpeg",
                nombre: "Weight scale",
                medicion_anterior: false,
                medicion_ultima_asistencia: false,
                estado: 0,
                ultimo_uso: "22 ago. 2022 03:30 pm",
                valores: {
                    peso: 0,
                },
            },
        ]);

        const mostrarModalObservacion = ref(false);
        const observacion = ref("");
        const observacionEstado = ref(false);

        const recomendaciones = ref([
            {
                nombre: "Realizar medición temperatura",
                estado: 0,
                periodo: 0,
                periodicidad: [],
            },
            {
                nombre: "Tomar ibuprofeno cada 8 horas",
                estado: 0,
                periodo: 0,
                periodicidad: [],
            },
            {
                nombre: "Ejericio aerobico todos los días",
                estado: 0,
                periodo: 0,
                periodicidad: [],
            },
        ]);

        function solicitarMedicion(index) {
            dispositivos["value"][index].estado = 1;
            setTimeout(() => {
                dispositivos["value"][index].estado = 2;
            }, 5000);
        }

        function guardarObservacion() {
            mostrarModalObservacion.value = false;
            observacionEstado.value = true;
        }

        function onDraggableChange(event) {
            console.log(event);
        }

        return {
            dispositivos,
            solicitarMedicion,
            observacionEstado,
            guardarObservacion,
            mostrarModalObservacion,
            recomendaciones,
            onDraggableChange,
        };
    },
};
</script>
<style lang="scss">
@font-face {
    font-family: "DS-digital";
    src: url("../../../../@core/assets/fonts/ds_digital/DS-DIGI.TTF")
            format("embedded-opentype"),
        /* Internet Explorer */
            url("../../../../@core/assets/fonts/ds_digital/DS-DIGI.TTF")
            format("woff2"),
        /* Super Modern Browsers */
            url("../../../../@core/assets/fonts/ds_digital/DS-DIGI.TTF")
            format("woff"),
        /* Pretty Modern Browsers */
            url("../../../../@core/assets/fonts/ds_digital/DS-DIGI.TTF")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../../../@core/assets/fonts/ds_digital/DS-DIGI.TTF")
            format("svg"); /* Legacy iOS */
}

.device-font {
    font-family: "DS-digital";
    font-size: 40px;
    line-height: 1;
}
.indicador-medicion {
    height: 100px;
    width: 200px;
    border-radius: 12px;
}
.indicador-medicion-pasada {
    height: 80px;
    width: 200px;
    border-radius: 12px;
}
</style>
